<template>
  <div id="allocations-bg" style="min-height: 100vh">
    <div class="allocation-container">
      <a class="allocation-header-back-page" @click="$router.go(-1)">
        <font-awesome-icon
          icon="fa-solid fa-arrow-left"
          class="allocation-header-back-page-icon"
        />
        <span class="allocation-header-back-page-text">{{
          $t("allocations")
        }}</span>
      </a>
      <div v-if="loading" class="item-center">
        <v-progress-circular
          indeterminate
          size="70"
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <div class="allocation-header">
          <div class="allocation-card">
            <div v-if="roundDataLoading" class="loading">
              <v-progress-circular
                indeterminate
                size="70"
                color="primary"
              ></v-progress-circular>
            </div>
            <div v-else class="allocation-round-container">
              <div class="allocaitons-round-header">
                <img
                  class="allocation-round-card-company-logo"
                  :src="roundData.company.logo"
                  alt="company logo"
                />
                <div class="allocation-round-data">
                  <span class="allocation-round-id">
                    {{ $t("single_round") + ` #${roundData.roundId}` }}
                  </span>
                  <span class="allocation-company-name">
                    {{ roundData.company.name }}
                  </span>
                  <p
                    class="round-details-allocations-header-status"
                    :style="`color:${selectOpportunityTextColor}`"
                  >
                    <font-awesome-icon
                      :color="statusCircleIconColor"
                      icon="fa-solid fa-circle"
                      class="round-details-allocations-header-circle-icon"
                    />
                    {{ $t(selectOpportunityText) }}
                  </p>
                </div>
              </div>
              <div>
                <div class="round-allocations-card-info">
                  <span class="round-allocations-card-title">
                    {{ $t("total_allocations") }}
                  </span>
                  <div class="round-allocations-card-values">
                    <span
                      v-for="(invested, i) in roundData.totalInvested"
                      :key="i"
                      class="round-allocations-card-value"
                      >{{
                        invested.value
                          ? formatToCurrency(invested.currency, invested.value)
                          : "-"
                      }}</span
                    >
                  </div>
                  <span class="round-allocations-last-update">{{
                    $t("last_update_in", { date: lastUpdate })
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="allocation-card">
            <div class="allocation-details-investor-info">
              <div class="investor-data">
                <span class="investor">Acionista</span>
                <span class="investor-name">{{
                  allocationData.investorName
                }}</span>
                <span
                  class="investor-profile"
                  @click="goToInvestorProfile(allocationData.investorId)"
                  >Ver perfil do investidor</span
                >
              </div>
              <div class="investor-data">
                <span class="allocation-value-label">Valor da alocação</span>
                <span class="allocation-value">{{
                  formatToCurrency(
                    allocationData.currency,
                    allocationData.value
                  )
                }}</span>
              </div>
            </div>
            <span class="investor-last-update"
              >Solicitação de alocação iniciada em
              {{ this.startedAllocation }}</span
            >
          </div>
        </div>
        <div class="allocation-details-container" v-if="!loading">
          <div class="allocation-details-header">
            <div class="allocation-details-header-content">
              <span class="allocation-title"> Andamento da alocação </span>
              <span class="allocation-last-update">
                Última atualização em
                {{ formatDate(allocationData.updatedAt) }} por
                {{ allocationData.updatedBy }}
              </span>
            </div>
            <div class="btn-cancel-allocation">
              <NewDxaButton :title="$t('cancel_allocation')" :disabled="true" />
            </div>
          </div>
          <div class="allocation-details-panels">
            <v-expansion-panels id="allocation-details-panels">
              <AllocationRequest
                :allocationData="allocationData"
                :allocationId="allocationId"
              />
              <AllocationAnalysis
                :allocationId="allocationId"
                :allocationData="allocationData"
              />
              <AllocationFormalization
                :allocationId="allocationId"
                :allocationData="allocationData"
                :disabledComponent="allocationIsFormalization"
              />
              <AllocationTransfer
                :allocationId="allocationId"
                :allocationData="allocationData"
              />
            </v-expansion-panels>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { roundStatusEnums } from "@/views/pages/round/RoundStatusEnums.js";
import { format } from "date-fns";
import ApiService from "@/services/ApiService";
import ptBR from "date-fns/locale/pt-BR";
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import AllocationAnalysis from "./components/AllocationSteps/AllocationAnalysis.vue";
import AllocationRequest from "./components/AllocationSteps/AllocationRequest.vue";
import AllocationFormalization from "./components/AllocationSteps/AllocationFormalization.vue";
import AllocationTransfer from "./components/AllocationSteps/AllocationTransfer.vue";
import { allocationStatusEnums } from "./AllocationStatusEnums";

export default {
  name: "AllocationsDetails",
  components: {
    NewDxaButton,
    AllocationAnalysis,
    AllocationRequest,
    AllocationFormalization,
    AllocationTransfer,
  },
  data() {
    return {
      statusCircleIconColor: null,
      selectOpportunityText: null,
      roundDetailsStatus: null,
      formatToCurrency: formatCurrency,
      roundData: null,
      roundDataLoading: false,
      allocationData: null,
      apiService: new ApiService(),
      loading: false,
      startedAllocation: null,
      allocationIsFormalization: false,
      allocationStatusEnums,
      updatedAt: null,
      allocationId: null,
    };
  },
  async created() {
    await this.getAllocationData();
  },
  methods: {
    goToInvestorProfile(investorId) {
      this.$router.push(`/users/edit/${investorId}`);
    },
    formatDate(date) {
      return format(new Date(date), "dd/MM/yyyy", { locale: ptBR });
    },
    async getAllocationData() {
      this.loading = true;
      const allocationId = Number(this.$route.params.id);
      this.allocationId = allocationId;
      await this.apiService
        .getRequest(`companyinterest/details/${allocationId}`)
        .then((result) => {
          this.allocationData = result.content;
          this.getRoundData(result.content.roundId);
          this.updatedAt = this.formatDate(result.content.updatedAt);
          this.startedAllocation = this.formatDate(
            result.content.startedAllocation
          );

          this.allocationIsFormalization =
            this.allocationData.status !==
            this.allocationStatusEnums.FORMALIZATION;
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.loading = false;
    },

    async getRoundData(roundId) {
      if (roundId == 0) {
        this.$toast.error(this.$t("allocation_no_round"));
        return;
      }
      this.roundDataLoading = true;
      await this.apiService
        .getRequest(`investmentround/${roundId}/allocations`)
        .then((result) => {
          this.roundData = result.content;
          this.lastUpdate = format(
            new Date(result.content.lastUpdate),
            "dd/MM/yyyy",
            {
              locale: ptBR,
            }
          );
          this.showRoundStatusLabel();
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.roundDataLoading = false;
    },
    showRoundStatusLabel() {
      switch (this.roundData.roundStatus) {
        case roundStatusEnums.AWAITING_PUBLISH:
          this.statusCircleIconColor = "#DADADA";
          this.selectOpportunityText = "round_status_await_publish";
          this.roundDetailsStatus = this.$t("round_status_created");
          break;
        case roundStatusEnums.PUBLISHED:
          this.statusCircleIconColor = "#01D181";
          this.selectOpportunityText = "opportunity_open";
          this.roundDetailsStatus = this.$t("round_status_published");
          break;
        case roundStatusEnums.CLOSED:
          const documentStyle = window.getComputedStyle(
            document.documentElement
          );
          const documentDarkColor = documentStyle.getPropertyValue("--dark");

          this.statusCircleIconColor = documentDarkColor;
          this.selectOpportunityText = "opportunity_closed";
          this.selectOpportunityTextColor = documentDarkColor;
          this.roundDetailsStatus = this.$t("round_status_closed");
          break;
        case roundStatusEnums.CANCELED:
          this.statusCircleIconColor = "#FF0000";
          this.selectOpportunityText = "opportunity_canceled";
          this.selectOpportunityTextColor = "#FF0000";
          this.roundDetailsStatus = this.$t("round_status_canceled");
          break;
        default:
          this.selectOpportunityTextColor = "#535353";
      }
    },
  },
};
</script>

<style scoped>
#allocations-bg {
  position: absolute;
  top: -75px;
  width: 100%;
  min-height: 100vh !important;
  background-color: var(--white);
}

.allocation-container {
  /* 75px precisa ser fixo para das a distancia do AppBar */
  margin-top: calc(75px + 20px);
  width: 100%;
  padding: 0 60px;
  background-color: var(--white);
  padding-bottom: 60px;
}

#allocation-details-panels div + div {
  margin-top: 20px;
}

.allocation-card {
  gap: 8px;
  background: var(--white);
  box-shadow: 0px 2px 2px rgba(30, 29, 29, 0.2);
  border-radius: 12px;
  position: relative;
}

.allocation-header {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.allocation-header-back-page {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
}

.allocation-header-back-page-icon {
  width: 22px;
  height: 22px;
  color: var(--primary);
}

.allocation-header-back-page-text {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.allocation-round-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.allocaitons-round-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
}

.allocation-round-card-company-logo {
  height: 96px;
  width: 96px;
}

.allocation-round-data {
  display: flex;
  flex-direction: column;
}

.allocation-round-id {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
}

.allocation-company-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-top: 4px;
  margin-bottom: 8px;
}

.round-details-allocations-header-status {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;

  display: flex;
  align-items: center;
  gap: 8px;
}

.round-allocations-card-info {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.round-allocation-card-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-allocations-card-values {
  margin-top: 8px;
}

.round-allocations-card-value {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.round-allocations-last-update {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
  margin-top: 8px;
}

.allocation-details-investor-info {
  padding: 28px 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.investor-data {
  display: flex;
  flex-direction: column;
}

.investor {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
}

.investor-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-top: 8px;
}

.investor-profile {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin-top: 8px;
  cursor: pointer;
}

.allocation-value-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
}

.allocation-value {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-top: 8px;
}

.investor-last-update {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.allocation-details-header-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.allocation-details-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn-cancel-allocation {
  width: 15%;
}

.allocation-details-container {
  margin-top: 40px;
}

.allocation-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.allocation-last-update {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #535353;
}

.allocation-details-panels {
  margin-top: 28px;
}

.loading {
  padding: 20px;
}

.item-center {
  display: grid;
  place-items: center;
}
</style>
