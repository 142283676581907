export const AllocationAnalysisEnum = {
  ALLOCATION_APPROVED: 1,
  ALLOCATION_WAITING_APPROVED: 2,
  ALLOCATION_DENIED: 3,
};

export const BackgroundCheckStatusEnum = {
  PROCESSING: 0,
  ANALYSIS: 1,
  CONCLUDED: 2,
};

export const BackgroundCheckResultEnum = {
  NOT_REVIEWED: 0,
  REVIEW: 1,
  INVALID: 2,
  VALID: 3,
};
