export const AllocationFormalizationEnum = {
  WAITING_USER_FORMALIZATION: 0,
  ALLOCATION_FORMALIZED: 1,
};

export const AllocationFormalizationFilledLoading = {
  BYLAWS: 1,
  SHARE_HOLDER_AGREEMENT: 2,
  ADHESION_TERM: 3,
  NEWS_LETTER_SUBSCRIPTION: 4,
  SUBSCRIPTION_AGREEMENT: 5
};
