export const userFileEnums = {
  MANAGED_PORTFOLIO_CONTRACT: 1,
  INVESTMENT_PROXY: 2,
  COMMITMENT_TERM: 3,
  CONTRACT_RENEW: 4,
  ALPHA_CONTRACT_AMENDMENT: 5,
  USE_TERMS: 6,
  NEWS_LETTER_SUBSCRIPTION: 7,
  ADHESION_TERM: 8,
  OTHERS: 9,
  TRANSFER_RECEIPT: 10,
  CONFIDENTIALITY_TERM: 11,
  SUBSCRIPTION_AGREEMENT: 12,
};
