<template>
  <div v-if="allLoading" class="item-center mb-4">
    <v-progress-circular
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </div>
  <v-expansion-panel
    v-else
    :class="{
      'background-transparent': checkFormalizationStatusTransparent(),
    }"
    class="allocation-panel"
  >
    <v-expansion-panel-header
      :class="{ disabled: loading }"
      :disabled="loading"
    >
      <div class="allocation-panel-header">
        <div class="allocation-panel-title">
          <div>
            <font-awesome-icon
              :color="getAllocationStatusTextAndIcon().iconColor"
              class="allocation-analysis-header-title-icon"
              :icon="`fa-solid ${getAllocationStatusTextAndIcon().icon}`"
            />
            <span
              class="allocation-request-title"
              :class="{ 'disabled-color': loading }"
              >Solicitação da alocação</span
            >
          </div>
          <span
            class="allocation-request-status"
            :style="`color: ${getAllocationStatusTextAndIcon().textColor}`"
            >{{ $t(getAllocationStatusTextAndIcon().text) }}</span
          >
          <div v-if="allocationRequestData.HasPreviousAllocation">
            <span class="has-previous-disclaimer"
              >Entre em contato com o investidor para organizar o processo de
              unificar a alocação.</span
            >
          </div>
        </div>
        <div class="allocation-request-dates">
          <div class="request-step">
            <span class="step-label" :class="{ 'disabled-color': loading }">{{
              $t("step_start")
            }}</span>
            <span class="step-date" :class="{ 'disabled-color': loading }">{{
              requestStartDate
            }}</span>
          </div>
          <div class="request-step">
            <span class="step-label" :class="{ 'disabled-color': loading }">{{
              $t("step_completion")
            }}</span>
            <span
              v-if="requestEndDate"
              class="step-date"
              :class="{ 'disabled-color': loading }"
              >{{ requestEndDate }}</span
            >
            <span class="no-data" v-else>-</span>
          </div>
        </div>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <div class="separator"></div>

      <div class="documents-section">
        <div class="documents-section-header">
          <span class="documents">Documentos</span>
          <span class="signature"> Data de assinatura</span>
        </div>
        <div class="document-row">
          <div
            class="file-section"
            @click="
              downloadFile(
                allocationRequestData.commitmentTermFileId,
                AllocationRequestFilesEnums.COMMITMENT_TERM,
                allocationRequestData.commitmentTermSigned
              )
            "
          >
            <v-progress-circular
              v-if="
                fileDownloadLoading.loading &&
                fileDownloadLoading.file ===
                  AllocationRequestFilesEnums.COMMITMENT_TERM
              "
              indeterminate
              size="20"
              color="primary"
            ></v-progress-circular>
            <font-awesome-icon
              v-else
              :class="{
                'disabled-color': !allocationRequestData.commitmentTermSigned,
              }"
              class="icon-success icon-download"
              icon="fa-solid fa-download"
            />
            <span
              class="document-name"
              :class="{
                'disabled-color': !allocationRequestData.commitmentTermSigned,
              }"
              >{{ $t("commitment_term") }}</span
            >
          </div>
          <span
            v-if="allocationRequestData.commitmentTermSigned"
            class="document-signature-date"
            >{{
              getAllocationDateParsed(
                allocationRequestData.commitmentTermSignedDate
              )
            }}</span
          >
          <span v-else>- </span>
        </div>
        <div class="separator"></div>
        <div class="document-row">
          <div
            class="file-section"
            @click="
              downloadFile(
                allocationRequestData.investmentProxyFileId,
                AllocationRequestFilesEnums.INVESTMENT_PROXY,
                allocationRequestData.signedInvestmentProxy
              )
            "
          >
            <v-progress-circular
              v-if="
                fileDownloadLoading.loading &&
                fileDownloadLoading.file ===
                  AllocationRequestFilesEnums.INVESTMENT_PROXY
              "
              indeterminate
              size="20"
              color="primary"
            ></v-progress-circular>
            <font-awesome-icon
              v-else
              :class="{
                'disabled-color': !allocationRequestData.signedInvestmentProxy,
              }"
              class="icon-success icon-download"
              icon="fa-solid fa-download"
            />
            <span
              class="document-name"
              :class="{
                'disabled-color': !allocationRequestData.signedInvestmentProxy,
              }"
              >{{ $t("proxy") }}</span
            >
          </div>
          <span
            v-if="allocationRequestData.signedInvestmentProxy"
            class="document-signature-date"
            >{{
              getAllocationDateParsed(
                allocationRequestData.signedInvestmentProxyDate
              )
            }}</span
          >
          <span v-else>-</span>
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ApiService from "@/services/ApiService";
import { allocationStatusEnums } from "../../AllocationStatusEnums";
import { format } from "date-fns";
import { AllocationRequestFilesEnums } from "../../enums/AllocationRequestEnums";
import ptBR from "date-fns/locale/pt-BR";
export default {
  name: "AllocationRequest",
  props: {
    allocationId: Number,
    allocationData: Object,
  },
  data() {
    return {
      allocationRequestData: null,
      loading: false,
      apiService: new ApiService(),
      allocationStatusEnums: allocationStatusEnums,
      fileDownloadLoading: false,
      AllocationRequestFilesEnums: AllocationRequestFilesEnums,
      requestStartDate: null,
      requestEndDate: null,
      allLoading: false,
    };
  },
  created() {
    this.getAllocationRequestData();
  },
  methods: {
    getAllocationStatusTextAndIcon() {
      const status = {
        [allocationStatusEnums.PLACEMENT_REVIEW]: {
          textColor: "var(--dark)",
          text: "approved_allocation",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
        },
        [allocationStatusEnums.FORMALIZATION]: {
          textColor: "var(--dark)",
          text: "approved_allocation",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
        },
        [allocationStatusEnums.TRANSFER_FUNDS]: {
          textColor: "var(--dark)",
          text: "approved_allocation",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
        },
        [allocationStatusEnums.OPERATION_SETTING]: {
          textColor: "var(--dark)",
          text: "approved_allocation",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
        },
        [allocationStatusEnums.SENT_CAPTABLE]: {
          textColor: "var(--dark)",
          text: "approved_allocation",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
        },
        [allocationStatusEnums.DENIED]: {
          textColor: "#FF0000",
          text: "denied_allocation",
          icon: "fa-circle-xmark",
          color: "#FF0000",
          iconColor: "#FF0000",
        },
        [allocationStatusEnums.CANCELLED]: {
          textColor: "#FF0000",
          text: "denied_allocation",
          icon: "fa-circle-xmark",
          color: "#FF0000",
          iconColor: "#FF0000",
        },
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: {
          textColor: "#7833FF",
          text: "interest_status_waiting_investor",
          icon: "fa-triangle-exclamation",
          color: "#FF9601",
          iconColor: "#FF9601",
        },
        [allocationStatusEnums.PLACEMENT_REQUEST]: {
          textColor: "#7833FF",
          text: "interest_status_waiting_investor",
          icon: "fa-triangle-exclamation",
          color: "#FF9601",
          iconColor: "#FF9601",
        },
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: {
          textColor: "#7833FF",
          text: "interest_status_waiting_investor",
          icon: "fa-triangle-exclamation",
          color: "#FF9601",
          iconColor: "#FF9601",
        },
      };

      return status[this.allocationData.status];
    },
    checkFormalizationStatusTransparent() {
      const literal = {
        [allocationStatusEnums.FORMALIZATION]: true,
        [allocationStatusEnums.TRANSFER_FUNDS]: true,
        [allocationStatusEnums.OPERATION_SETTING]: true,
        [allocationStatusEnums.SENT_CAPTABLE]: true,
        [allocationStatusEnums.CANCELLED]: true,
        [allocationStatusEnums.DENIED]: true,
        [allocationStatusEnums.PLACEMENT_REVIEW]: true,
        [allocationStatusEnums.PLACEMENT_REQUEST]: false,
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: false,
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: false,
      };
      return literal[this.allocationData.status];
    },
    async downloadFile(fileId, currentFile, signed) {
      if (!signed || !currentFile || !fileId) return;
      this.fileDownloadLoading = {
        loading: true,
        file: currentFile,
      };

      const currentFileName = {
        [AllocationRequestFilesEnums.COMMITMENT_TERM]: "commitment_term",
        [AllocationRequestFilesEnums.INVESTMENT_PROXY]: "investment_proxy",
      };
      await this.apiService
        .getRequest(`investor/files/${fileId}`)
        .then((result) => {
          const link = document.createElement("a");
          result = "data:application/octet-stream;base64," + result;
          link.href = result;
          link.setAttribute(
            "download",
            `${this.$t(currentFileName[currentFile])}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        });

      setTimeout(() => {
        this.fileDownloadLoading.loading = false;
      }, 1500);
    },
    async getAllocationRequestData() {
      this.allLoading = true;
      await this.apiService
        .getRequest(`companyinterest/allocation-request/${this.allocationId}`)
        .then((result) => {
          this.allocationRequestData = result.content;
          this.requestStartDate = this.getAllocationDateParsed(
            this.allocationData.startedAllocation
          );

          // Verifico se no statusRecords já tem o status 0 ou 3
          let allocationRequestDone = this.allocationData.statusRecords.some(
            (statusRecord) => {
              return (
                statusRecord.status === allocationStatusEnums.FORMALIZATION ||
                statusRecord.status === allocationStatusEnums.PLACEMENT_REVIEW
              );
            }
          );
          // Se tiver termo de compromisso assinado
          // ou tiver já no status de formalização,
          if (
            this.allocationRequestData.commitmentTermSigned ||
            allocationRequestDone
          ) {
            this.requestEndDate = this.getAllocationDateParsed(
              this.allocationRequestData.commitmentTermSignedDate
            );
          }
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.allLoading = false;
    },
    getAllocationDateParsed(date) {
      return format(new Date(date), "dd/MM/yyy", {
        locale: ptBR,
      });
    },
  },
};
</script>

<style scoped>
.no-data {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}
.allocatino-panel {
  background: var(--white);
  border: 1px solid #afafaf;
  border-radius: 12px;
}

.icon-success {
  color: var(--primary);
  width: 28px;
  height: 28px;
}

.allocation-request-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-left: 12px;
}

.allocation-panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.allocation-request-dates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20px;
  gap: 20px;
}

.request-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
}

.step-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.step-date {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.allocation-panel-title {
  display: flex;
  flex-direction: column;
}

.allocation-request-status {
  margin-top: 20px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.separator {
  background: #afafaf;
  opacity: 0.5;
  border-radius: 0px;
  height: 1px;
}

.documents-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.documents {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.signature {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.document-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.icon-download {
  font-size: 24px;
}

.document-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin-left: 20px;
}

.document-signature-date {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.disabled {
  background: var(--white);
}

.disabled-color {
  color: #afafaf;
  cursor: not-allowed;
}

.file-section {
  cursor: pointer;
  margin-bottom: 20px;
}

.cancelled-status {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.15px;
  color: #ff0000;
}

.investor-has-allocation {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #ff9601;
}

.has-previous-disclaimer {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.background-transparent {
  background-color: transparent !important;
}

.allocation-analysis-header-title-icon {
  width: 28px;
  height: 28px;
}

.item-center {
  display: grid;
  place-items: center;
}
</style>
