<template>
  <v-expansion-panels>
    <div v-if="allLoading" class="item-center">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>
    <v-expansion-panel
      v-else
      :style="!checkAllocationDisabled() && 'padding-bottom: 40px'"
      :class="{
        'background-transparent':
          checkTransferStatusTransparent() || disabledButtonConfirmTransfer(),
      }"
    >
      <v-expansion-panel-header id="allocation-transfer-header">
        <div
          class="allocation-transfer-header"
          :class="{
            'allocation-transfer-header-mb':
              getAllocationStatusTextAndIcon().marginBottom,
          }"
        >
          <h1
            class="allocation-transfer-header-title"
            :style="checkAllocationDisabled() && 'color: #AFAFAF'"
          >
            <font-awesome-icon
              :icon="`fa-solid ${getAllocationStatusTextAndIcon().icon}`"
              class="allocation-transfer-header-title-icon"
              :color="getAllocationStatusTextAndIcon().iconColor"
            />
            {{ $t("transfer") }}
          </h1>
          <div class="allocation-transfer-header-date-container">
            <div class="allocation-transfer-header-date-content">
              <span :style="checkAllocationDisabled() && 'color: #AFAFAF'">{{
                $t("step_start")
              }}</span>
              <span class="date" v-if="transferStartDate">{{
                transferStartDate
              }}</span>
              <span class="date" v-else>-</span>
            </div>
            <div class="allocation-transfer-header-date-content">
              <span :style="checkAllocationDisabled() && 'color: #AFAFAF'">{{
                $t("step_completion")
              }}</span>
              <span class="date" v-if="transferEndDate">{{
                transferEndDate
              }}</span>
              <span class="date" v-else>-</span>
            </div>
          </div>
        </div>
        <div
          class="allocation-transfer-header-status"
          v-if="!checkAllocationDisabled()"
        >
          <span
            class="allocation-transfer-status"
            :style="`color: ${getAllocationStatusTextAndIcon().color}`"
          >
            {{ $t(getAllocationStatusTextAndIcon().text) + "." }}
          </span>
          <span
            class="allocation-transfer-status"
            v-if="
              !checkAllocationDisabled() && !checkTransferStatusTransparent()
            "
          >
            {{ $t("allocation_transfer_message_alert_1") }}
          </span>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="" v-if="checkAllocationDisabled()">
          <span
            class="allocation-transfer-status"
            :style="`color: ${getAllocationStatusTextAndIcon().color}`"
          >
            {{ $t(getAllocationStatusTextAndIcon().text) + "." }}
          </span>
        </div>
        <div
          class="allocation-transfer-content"
          :style="checkAllocationDisabled() && 'margin-top: 0px'"
        >
          <div class="allocation-transfer-message-to-user">
            <h2>{{ $t("show_user_message_title") }}</h2>
            <div v-if="checkTransferStatusTransparent()">
              <p>
                {{ $t("allocation_transfer_message_alert_2") }}
              </p>
            </div>
            <div v-if="!checkTransferStatusTransparent()">
              <p>
                {{ $t("allocation_transfer_message_user_1") }}
              </p>
              <p>
                {{ $t("allocation_transfer_message_user_2") }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="allocation-transfer-bank-data-container"
          v-if="!checkAllocationDisabled()"
          :style="
            checkTransferStatusTransparent() && 'background-color: var(--white)'
          "
        >
          <ul class="allocation-transfer-bank-data-list">
            <li class="column-1">
              <div
                class="item-center cursor-pointer"
                @click="gs.copy_url_to_clipboard(allocationData.value)"
              >
                <font-awesome-icon :icon="['fas', 'copy']" />
                <span class="allocation-transfer-back-label-copy">{{
                  $t("copy")
                }}</span>
              </div>
              <div>
                <span class="label">{{ $t("investment_value_title") }}</span>
                <span class="value">{{
                  formatCurrency(allocationData.currency, allocationData.value)
                }}</span>
              </div>
            </li>
            <li class="column-1">
              <div
                class="item-center cursor-pointer"
                @click="
                  gs.copy_url_to_clipboard(allocationData.investmentVehicleCnpj)
                "
              >
                <!-- precisamos do cnpj -->
                <font-awesome-icon :icon="['fas', 'copy']" />
                <span class="allocation-transfer-back-label-copy">{{
                  $t("copy")
                }}</span>
              </div>
              <div>
                <span class="label">{{ $t("holder") }}</span>
                <span class="value">{{
                  allocationData.investmentVehicleName
                }}</span>
                <span class="value cnpj">{{
                  formatCnpj(allocationData.investmentVehicleCnpj)
                }}</span>
              </div>
            </li>
            <li class="column-1" v-if="vehicleAccount && vehicleAccount.bank">
              <div
                class="item-center cursor-pointer"
                @click="gs.copy_url_to_clipboard(getVehicleBankName())"
              >
                <font-awesome-icon :icon="['fas', 'copy']" />
                <span class="allocation-transfer-back-label-copy">{{
                  $t("copy")
                }}</span>
              </div>
              <div>
                <span class="label">{{ $t("bank_title") }}</span>
                <span class="value">{{ getVehicleBankName() }}</span>
              </div>
            </li>
            <li
              class="column-2 row-1"
              v-if="vehicleAccount && vehicleAccount.agency"
            >
              <div
                class="item-center cursor-pointer"
                @click="gs.copy_url_to_clipboard(vehicleAccount.agency)"
              >
                <font-awesome-icon :icon="['fas', 'copy']" />
                <span class="allocation-transfer-back-label-copy">{{
                  $t("copy")
                }}</span>
              </div>
              <div>
                <span class="label">{{ $t("agency") }}</span>
                <span class="value">{{ vehicleAccount.agency }}</span>
              </div>
            </li>
            <li
              class="column-2 row-2"
              v-if="vehicleAccount && vehicleAccount.account"
            >
              <div
                class="item-center cursor-pointer"
                @click="gs.copy_url_to_clipboard(vehicleAccount.account)"
              >
                <font-awesome-icon :icon="['fas', 'copy']" />
                <span class="allocation-transfer-back-label-copy">{{
                  $t("copy")
                }}</span>
              </div>
              <div>
                <span class="label">{{ $t("account_title") }}</span>
                <span class="value">{{ vehicleAccount.account }}</span>
              </div>
            </li>
            <li
              class="column-2 row-3"
              v-if="
                !checkTransferStatusTransparent() &&
                vehicleAccount &&
                vehicleAccount.pix
              "
            >
              <div
                class="item-center cursor-pointer"
                @click="gs.copy_url_to_clipboard(vehicleAccount.pix)"
              >
                <font-awesome-icon :icon="['fas', 'copy']" />
                <span class="allocation-transfer-back-label-copy">{{
                  $t("copy")
                }}</span>
              </div>
              <div>
                <span class="label">{{ $t("pix") }}</span>
                <span class="value">{{ vehicleAccount.pix }}</span>
              </div>
            </li>
            <li class="column-2 row-3" v-if="checkTransferStatusTransparent()">
              <div class="item-center cursor-pointer icon-disabled">
                <font-awesome-icon :icon="['fas', 'copy']" />
              </div>
              <!-- <div>
                  por hora esse campo vai ser ignorado
                <span class="label">{{ $t("deadline") }}</span>
                <span class="value">até DD/MM/AAAA</span>
              </div> -->
            </li>
          </ul>
        </div>
        <div
          class="allocation-transfer-register flex"
          v-if="!checkAllocationDisabled()"
          :style="
            checkTransferStatusTransparent() &&
            'border-bottom: none; border-top: none'
          "
        >
          <div class="allocation-transfer-register-section-1">
            <h2 class="allocation-transfer-register-title">
              {{ $t("transfer") }}
            </h2>
            <div class="flex allocation-transfer-register-personal-data">
              <div>
                <span class="label">{{ $t("shareholder") }}</span>
                <span class="value">{{ investorInfo.fullName }}</span>
              </div>
              <div>
                <span class="label">{{ $t("cpf") }}</span>
                <span class="value">{{ investorInfo.socialNumber }}</span>
              </div>
              <div v-if="checkTransferStatusTransparent()">
                <span class="label">{{ $t("transfer_date") }}</span>
                <span class="value">{{
                  getAllocationDateParsed(allocationData.depositedDate, false)
                }}</span>
              </div>
            </div>
            <div
              class="allocation-transfer-input-date-container"
              v-if="!checkTransferStatusTransparent()"
            >
              <span class="allocation-transfer-input-date-label">{{
                $t("extract_date_transfer")
              }}</span>
              <form>
                <v-menu
                  ref="MenuDateRef"
                  v-model="allocationMenuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      color="#AAAAAA"
                      background-color="white"
                      dense
                      class="allocation-transfer-input-date"
                      outlined
                      style="width: 100%"
                      v-model="allocationMenuDateText"
                      v-mask="['##/##/####']"
                      append-icon="mdi-calendar"
                      @blur="
                        allocationDate = formatDateOnBlur(
                          allocationMenuDateText
                        )
                      "
                      v-bind="attrs"
                      v-on="on"
                      label="DD/MM/AA"
                      height="34"
                      :rules="[required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-BR"
                    color="primary"
                    v-model="allocationDate"
                    min="1850-01-01"
                    @change="saveAllocationMenuDate"
                  ></v-date-picker>
                </v-menu>
              </form>
            </div>
          </div>
          <div
            class="allocation-transfer-register-section-2"
            v-if="!checkTransferStatusTransparent()"
          >
            <p>{{ $t("investor_not_confirm_transfer") }}</p>
            <div
              class="max-content allocation-transfer-register-section-2-button"
            >
              <NewDxaButton
                :loading="submitLoading"
                @btnFunction="handleConfirmTransfer"
                :title="$t('confirm_transfer')"
                :disabled="disabledButtonConfirmTransfer()"
              />
            </div>
          </div>
        </div>
        <div
          class="allocation-transfer-register-footer"
          v-if="!checkAllocationDisabled() && !checkTransferStatusTransparent()"
        >
          <p>
            {{ $t("request_new_transfer_title") }}
          </p>
          <span class="link">{{ $t("request_new_transfer") }}</span>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { AllocationTransferStatusEnum } from "../../enums/AllocationTransferEnum";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { formatCnpj } from "@/shared/helpers/cnpjHelper.js";
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import { allocationStatusEnums } from "../../AllocationStatusEnums";
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import banksData from "@/shared/data/banks.json";
import { parseStringDate } from "@/shared/helpers/dateHelper.js";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export default {
  name: "AllocationTransfer",
  components: {
    NewDxaButton,
  },
  props: {
    allocationData: Object,
    allocationId: Number,
  },
  data() {
    return {
      banks: banksData,
      allocationTransferStatus: null,
      AllocationTransferStatusEnum,
      formatCurrency,
      formatCnpj,
      allocationStatusEnums,
      apiService: new ApiService(),
      vehicleAccount: null,
      investorInfo: null,
      gs: new GeneralServices(),
      allocationMenuDate: null,
      allocationMenuDateText: null,
      allocationDate: null,
      submitLoading: false,
      transferStartDate: null,
      transferEndDate: null,
      allLoading: false,
    };
  },
  async created() {
    this.allocationTransferStatus = this.allocationData.status;

    await this.getVehicleInfo();
    await this.getUserBasicInfo();
    this.checkTransferDates();

    if (
      this.allocationData.depositedDate &&
      this.allocationData.depositedDate !== "0001-01-01T00:00:00"
    ) {
      this.allocationMenuDateText = format(
        parseStringDate(this.allocationData.depositedDate),
        "dd/MM/yyyy",
        { locale: ptBR }
      );
    }
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    checkTransferDates() {
      const filteredStartStatus = this.allocationData.statusRecords.filter(
        (record) => record.status === allocationStatusEnums.TRANSFER_FUNDS
      );

      const filteredEndStatus = this.allocationData.statusRecords.filter(
        (record) => record.status === allocationStatusEnums.OPERATION_SETTING
      );

      if (filteredStartStatus.length) {
        this.transferStartDate = this.getAllocationDateParsed(
          filteredStartStatus[0].startedStatusAt,
          false
        );
      }

      if (filteredEndStatus.length) {
        this.formalizationEndDate = this.getAllocationDateParsed(
          filteredEndStatus[0].startedStatusAt,
          false
        );
      }
    },
    getVehicleBankName() {
      let vehicleAccount = this.banks.find(
        (item) => item.COMPE === this.vehicleAccount.bank
      );

      return `${vehicleAccount.COMPE} ${vehicleAccount.LongName}`;
    },
    formatDateOnBlur(date) {
      const [day, month, year] = date.split("/");
      let formateDate;

      if (date.length) {
        formateDate = `${year}-${month}-${day}`;
      } else {
        formateDate = null;
      }

      return formateDate;
    },
    async getUserBasicInfo() {
      this.allLoading = true;
      await this.apiService
        .postRequest(`investor/basic`, {
          id: this.allocationData.investorId,
        })
        .then((response) => {
          this.investorInfo = response.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.allLoading = false;
    },
    saveAllocationMenuDate(date) {
      this.allocationMenuDateText = this.formatInputDate(this.allocationDate);
      this.$refs.MenuDateRef.save(date);
    },
    formatInputDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async getVehicleInfo() {
      if (this.allocationData.investmentVehicleId) {
        this.allLoading = true;
        await this.apiService
          .getRequest(
            `investmentvehicle/vehiclestockvalue/${this.allocationData.investmentVehicleId}`
          )
          .then((response) => {
            if (response.content.bankAccounts) {
              this.vehicleAccount = response.content.bankAccounts[0];
            }
          })
          .catch((err) => {
            this.$toast.error(this.$t("error_occoured"));
          });
        this.allLoading = false;
      }
    },
    async handleConfirmTransfer() {
      this.submitLoading = true;
      let payload = {
        finalized: true,
        id: this.allocationId,
        depositedValue: this.allocationData.value,
      };

      if (this.allocationDate && this.allocationDate.length) {
        payload.depositedDate = this.allocationDate.toString();
      }

      await this.apiService
        .postRequest(`companyinterest/follow-up-edit`, payload)
        .then((response) => {
          this.$toast.success(this.$t("saved"));
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.submitLoading = false;
    },
    disabledButtonConfirmTransfer() {
      const literal = {
        [allocationStatusEnums.DENIED]: true,
        [allocationStatusEnums.CANCELLED]: true,
        [allocationStatusEnums.PLACEMENT_REVIEW]: false,
        [allocationStatusEnums.FORMALIZATION]: false,
        [allocationStatusEnums.PLACEMENT_REVIEW]: false,
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: false,
        [allocationStatusEnums.PLACEMENT_REQUEST]: false,
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: false,
        [allocationStatusEnums.TRANSFER_FUNDS]: false,
        [allocationStatusEnums.OPERATION_SETTING]: false,
        [allocationStatusEnums.SENT_CAPTABLE]: false,
      };

      return literal[this.allocationTransferStatus];
    },
    getAllocationStatusTextAndIcon() {
      const status = {
        [allocationStatusEnums.PLACEMENT_REVIEW]: {
          text: "step_not_started",
          icon: "fa-hourglass-start",
          color: "#AFAFAF",
          iconColor: "#AFAFAF",
          marginBottom: true,
        },
        [allocationStatusEnums.FORMALIZATION]: {
          text: "step_not_started",
          icon: "fa-hourglass-start",
          color: "#AFAFAF",
          iconColor: "#AFAFAF",
          marginBottom: true,
        },
        [allocationStatusEnums.PLACEMENT_REQUEST]: {
          text: "step_not_started",
          icon: "fa-hourglass-start",
          color: "#AFAFAF",
          iconColor: "#AFAFAF",
          marginBottom: true,
        },
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: {
          text: "step_not_started",
          icon: "fa-hourglass-start",
          color: "#AFAFAF",
          iconColor: "#AFAFAF",
          marginBottom: true,
        },
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: {
          text: "step_not_started",
          icon: "fa-hourglass-start",
          color: "#AFAFAF",
          iconColor: "#AFAFAF",
          marginBottom: true,
        },
        [allocationStatusEnums.TRANSFER_FUNDS]: {
          text: "waiting_user_confirm_transfer_founds",
          icon: "fa-triangle-exclamation",
          color: "#7733FF",
          iconColor: "#FF9601",
          marginBottom: true,
        },
        [allocationStatusEnums.OPERATION_SETTING]: {
          text: "capitalized_reserve",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
          marginBottom: false,
        },
        [allocationStatusEnums.SENT_CAPTABLE]: {
          text: "capitalized_reserve",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
          marginBottom: false,
        },
        [allocationStatusEnums.DENIED]: {
          text: "denied_allocation",
          icon: "fa-circle-xmark",
          color: "#FF0000",
          iconColor: "#FF0000",
          marginBottom: true,
        },
        [allocationStatusEnums.CANCELLED]: {
          text: "denied_allocation",
          icon: "fa-circle-xmark",
          color: "#FF0000",
          iconColor: "#FF0000",
          marginBottom: true,
        },
      };

      return status[this.allocationTransferStatus];
    },
    checkAllocationDisabled() {
      const literal = {
        [allocationStatusEnums.PLACEMENT_REVIEW]: true,
        [allocationStatusEnums.FORMALIZATION]: true,
        [allocationStatusEnums.PLACEMENT_REVIEW]: true,
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: true,
        [allocationStatusEnums.PLACEMENT_REQUEST]: true,
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: true,
        [allocationStatusEnums.DENIED]: false,
        [allocationStatusEnums.TRANSFER_FUNDS]: false,
        [allocationStatusEnums.OPERATION_SETTING]: false,
        [allocationStatusEnums.SENT_CAPTABLE]: false,
        [allocationStatusEnums.CANCELLED]: false,
      };

      return literal[this.allocationTransferStatus];
    },
    checkTransferStatusTransparent() {
      const literal = {
        [allocationStatusEnums.OPERATION_SETTING]: true,
        [allocationStatusEnums.SENT_CAPTABLE]: true,
        [allocationStatusEnums.FORMALIZATION]: false,
        [allocationStatusEnums.TRANSFER_FUNDS]: false,
        [allocationStatusEnums.CANCELLED]: false,
        [allocationStatusEnums.DENIED]: false,
        [allocationStatusEnums.PLACEMENT_REVIEW]: false,
        [allocationStatusEnums.PLACEMENT_REQUEST]: false,
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: false,
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: false,
      };
      return literal[this.allocationTransferStatus];
    },
    getAllocationDateParsed(date, time = true) {
      if (!date) {
        return "-";
      }

      return format(new Date(date), `dd/MM/yyyy ${time ? "HH:mm" : ""}`, {
        locale: ptBR,
      });
    },
  },
};
</script>

<style scoped>
.background-transparent {
  background-color: transparent !important;
}

.allocation-transfer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.allocation-transfer-header-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);

  display: flex;
  align-items: center;
  gap: 18px;
}

.allocation-transfer-header-title > svg {
  width: 28px;
  height: 28px;
}

#allocation-transfer-header {
  position: relative;
}

.allocation-transfer-header-status {
  position: absolute;
  bottom: -20px;
  left: 0;
}

.allocation-transfer-header-date-content > span {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.allocation-transfer-header-date-content > span.date {
  font-weight: 700;
  margin-top: 8px;
}

.allocation-transfer-header-mb {
  margin-bottom: 32px !important;
}

.allocation-transfer-header-date-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
}

.allocation-transfer-status {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  margin-bottom: 10px;
  display: block;
  margin-left: 20px;
}

.allocation-transfer-content {
  margin-top: 32px;
  margin-bottom: 20px;
}

.allocation-transfer-message-to-user {
  background: var(--white);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
}

.allocation-transfer-message-to-user h2,
.allocation-transfer-message-to-user p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
}

.allocation-transfer-message-to-user h2 {
  font-weight: bold;
  margin-bottom: 4px;
}

.allocation-transfer-bank-data-container {
  border: 1px solid #afafaf;
  border-radius: 8px;
  padding: 20px;
  border-left: 8px solid var(--dark);
}

.allocation-transfer-bank-data-list {
  max-width: 40%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.allocation-transfer-bank-data-list > li span.label {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.15px;
  color: #afafaf;
  display: block;
  margin-bottom: 4px;
}

.allocation-transfer-bank-data-list > li span.value {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 13px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
}

.allocation-transfer-bank-data-list > li span.cnpj {
  font-weight: 500 !important;
  font-size: 14px;
  margin-top: 4px;
}

.allocation-transfer-bank-data-list > li {
  display: flex;
  align-items: center;
  gap: 20px;
}

.allocation-transfer-bank-data-list > li svg {
  color: var(--primary);
  font-size: 18px;
}

.allocation-transfer-back-label-copy {
  display: block;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin-top: 4px;
}

.allocation-transfer-register-title {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 30px;
}

.allocation-transfer-register-personal-data {
  gap: 40px;
  align-items: center;
}

.allocation-transfer-register {
  margin-top: 40px;
  border-top: 1px solid var(--dark);
  border-bottom: 1px solid var(--dark);

  padding-top: 40px;

  justify-content: space-between;
}

.allocation-transfer-register-personal-data > div span.label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
}

.allocation-transfer-register-personal-data > div span.value {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-top: 10px;
}

.allocation-transfer-input-date {
  max-width: 220px;
  border-radius: 4px;
}

.allocation-transfer-input-date-label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
  margin-bottom: 16px;
}

.allocation-transfer-input-date-container {
  margin-top: 60px;
}

.allocation-transfer-register-section-2 > p {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.15px;
  color: #7833ff;

  margin-bottom: 80px;
}

.allocation-transfer-register-section-2 {
  align-self: flex-end;
  display: grid;
}

.allocation-transfer-register-section-2-button {
  justify-self: end;
  margin-bottom: 20px;
}

.allocation-transfer-register-footer > p {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 20px;
  margin-top: 40px;
}

.allocation-transfer-register-footer > span.link {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  /* color: #007d4d; quando tiver controle desse status fazer a validação das cores */
  color: #afafaf;
  cursor: pointer;
}

.icon-disabled {
  opacity: 0;
  pointer-events: none;
}

/* classes utilitárias */
.max-content {
  max-width: max-content;
}

.flex {
  display: flex;
}

.item-center {
  display: grid;
  place-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.column-1 {
  grid-column: 1;
}

.column-2 {
  grid-column: 2;
}

.row-1 {
  grid-row: 1;
}

.row-2 {
  grid-row: 2;
}

.row-3 {
  grid-row: 3;
}
</style>
