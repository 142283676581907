<template>
  <v-expansion-panels>
    <div v-if="allLoading" class="item-center mb-4">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>
    <v-expansion-panel
      v-else
      :class="{
        'background-transparent': checkAnalysisStatusTransparent(),
      }"
    >
      <v-expansion-panel-header id="allocation-analysis-header">
        <div class="allocation-analysis-header">
          <h1
            class="allocation-analysis-header-title"
            :style="checkAllocationDisabled() && 'color: #AFAFAF'"
          >
            <font-awesome-icon
              :icon="`fa-solid ${getAllocationStatusTextAndIcon().icon}`"
              class="allocation-analysis-header-title-icon"
              :color="getAllocationStatusTextAndIcon().iconColor"
            />
            {{ $t("allocation_of_analysis") }}
          </h1>
          <div class="allocation-analysis-header-date-container">
            <div class="allocation-analysis-header-date-content">
              <span :style="checkAllocationDisabled() && 'color: #AFAFAF'">{{
                $t("step_start")
              }}</span>
              <span class="date" v-if="analysisStartDate">{{
                analysisStartDate
              }}</span>
              <span class="date" v-else>-</span>
            </div>
            <div class="allocation-analysis-header-date-content">
              <span :style="checkAllocationDisabled() && 'color: #AFAFAF'">{{
                $t("step_completion")
              }}</span>
              <span class="date" v-if="analysisEndDate"
                >{{ analysisEndDate }}
              </span>
              <span v-else class="date">-</span>
            </div>
          </div>
        </div>
        <div class="allocation-analysis-header-status">
          <span
            v-if="!checkAllocationDisabled()"
            class="allocation-analysis-status"
            :style="`color: ${getAllocationStatusTextAndIcon().color}`"
          >
            {{ $t(getAllocationStatusTextAndIcon().text) + "." }}
          </span>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          class="allocation-analysis-content"
          :class="{ 'border-bottom': !checkAllocationDisabled() }"
        >
          <span
            v-if="checkAllocationDisabled()"
            class="allocation-analysis-status"
            :style="`color: ${getAllocationStatusTextAndIcon().color}`"
          >
            Etapa não iniciada</span
          >
          <!-- esse color e texto vai ser dinâmico -->
          <div class="allocation-analysis-content-section-1">
            <div class="allocation-analysis-message-to-user">
              <h2>{{ $t("show_user_message_title") }}</h2>
              <p>
                {{ $t("show_user_message_content") }}
              </p>
            </div>
          </div>
          <div
            class="allocation-analysis-content-section-2"
            v-if="!checkAllocationDisabled()"
          >
            <div
              class="allocation-analysis-user-background-check"
              :class="{
                'border-bottom-none':
                  allocationAnalysisStatus === allocationStatusEnums.DENIED ||
                  allocationAnalysisStatus === allocationStatusEnums.CANCELLED,
              }"
            >
              <div>
                <h2 class="allocation-analysis-section-title">
                  {{ $t("user_background_check") }}
                </h2>
                <ul
                  class="allocation-analysis-user-background-check-list"
                  v-if="backgroundCheckData"
                >
                  <li>
                    <span class="label">{{ $t("status") }}</span>
                    <span class="value">{{
                      $t(getBackgroundCheckStatus())
                    }}</span>
                  </li>
                  <li>
                    <span class="label">{{ $t("last_update") }}</span>
                    <span class="value">{{
                      getAllocationDateParsed(backgroundCheckData.LastUpdate)
                    }}</span>
                  </li>
                  <li>
                    <span class="label">{{ $t("result") }}</span>
                    <span class="value">{{
                      $t(getBackgroundCheckResult())
                    }}</span>
                  </li>
                </ul>
              </div>
              <div
                class="allocation-analysis-user-background-check-buttons-flex"
              >
                <div v-if="!checkAnalysisStatusTransparent()">
                  <NewDxaButton
                    :outline="true"
                    :title="$t('update_status')"
                    @btnFunction="handleClickUpdateAllocationStatus"
                    :loading="updateAllocationStatusLoading"
                    :disabled="disableUpdateAllocationStatus"
                  />
                </div>
                <div v-if="!checkAnalysisStatusTransparent()">
                  <NewDxaButton
                    :outline="true"
                    :title="$t('request_new_status')"
                    @btnFunction="handleClickRequestNewAllocationStatus"
                    :loading="requestNewAllocationStatusLoading"
                    :disabled="disableRequestNewAllocationStatus"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="allocation-analysis-content-section-3"
            v-if="!checkAllocationDisabled()"
          >
            <h2
              class="allocation-analysis-section-title"
              v-if="
                allocationAnalysisStatus !== allocationStatusEnums.DENIED &&
                allocationAnalysisStatus !== allocationStatusEnums.CANCELLED
              "
            >
              {{ $t("allocation_status") }}
            </h2>
            <div v-if="checkAnalystisAllocationStatus()">
              <ul class="allocation-analysis-allocation-status-list">
                <li>
                  <span class="label">{{ $t("status") }}</span>
                  <span class="value">{{ $tc("approved", 1) }}</span>
                </li>
                <li>
                  <span class="label">{{ $t("vehicle_title_single") }}</span>
                  <span class="value">{{
                    allocationData.investmentVehicleName
                  }}</span>
                </li>
              </ul>
            </div>
            <v-form
              @submit.stop.prevent="checkForm()"
              v-if="
                allocationAnalysisStatus ===
                allocationStatusEnums.PLACEMENT_REVIEW
              "
              ref="form"
            >
              <v-radio-group
                class="radio-group"
                row
                v-model="allocationStatus"
                @change="(e) => vehicleIsRequired(e)"
              >
                <v-radio
                  color="primary"
                  :value="allocationStatusEnums.FORMALIZATION"
                >
                  <template v-slot:label>
                    <span class="allocation-analysis-radiou-label">{{
                      $t("approved_allocation")
                    }}</span>
                  </template>
                </v-radio>
                <v-radio color="primary" :value="allocationStatusEnums.DENIED">
                  <template v-slot:label>
                    <span class="allocation-analysis-radiou-label">{{
                      $t("denied_allocation")
                    }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
              <div class="allocation-analysis-select-investment-vehicle">
                <div
                  class="allocation-analysis-select-investment-input-container"
                >
                  <span
                    class="input-label"
                    :class="{
                      'input-label-disabled': disableInputSelectVehicle,
                    }"
                    >{{ $t("select_investment_vehicle_to_allocation") }}</span
                  >
                  <v-autocomplete
                    :disabled="disableInputSelectVehicle"
                    :items="vehicleOptions"
                    item-text="name"
                    item-value="id"
                    color="primary"
                    dense
                    :label="$t('select')"
                    outlined
                    v-model="selectedVehicle"
                    :rules="vehicleInputIsRequired ? [required] : []"
                    :loading="vehicleOptionsLoading"
                  >
                    <template v-slot:progress>
                      <v-progress-circular
                        indeterminate
                        style="position: absolute; right: 40px; top: 10px"
                        size="20"
                        color="primary"
                      ></v-progress-circular>
                    </template>
                  </v-autocomplete>
                </div>
                <div
                  class="allocation-analysis-select-investment-vehicle-button-save"
                >
                  <NewDxaButton
                    type="submit"
                    :title="$t('save')"
                    :disabled="disableButtonSaveVehicle"
                    :loading="submitLoading"
                  />
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import {
  AllocationAnalysisEnum,
  BackgroundCheckResultEnum,
  BackgroundCheckStatusEnum,
} from "../../enums/AllocationAnalysisEnum";
import ApiService from "@/services/ApiService";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { allocationStatusEnums } from "../../AllocationStatusEnums";

export default {
  name: "AllocationAnalysis",
  components: {
    NewDxaButton,
  },
  data() {
    return {
      apiService: new ApiService(),
      disableInputSelectVehicle: true,
      disableButtonSaveVehicle: true,
      allocationStatus: null,
      selectedVehicle: null,
      requestNewAllocationStatusLoading: false,
      updateAllocationStatusLoading: false,
      disableUpdateAllocationStatus: false,
      disableRequestNewAllocationStatus: false,
      // por hora está mocado, porém deve alinhar esse status com o back
      allocationAnalysisStatus: 0,
      AllocationAnalysisEnum,
      userId: null,
      backgroundCheckData: null,
      BackgroundCheckStatusEnum,
      vehicleOptions: [],
      vehicleOptionsLoading: false,
      allocationStatusEnums,
      submitLoading: false,
      vehicleInputIsRequired: true,
      analysisStartDate: null,
      analysisEndDate: null,
      allLoading: false,
    };
  },
  props: {
    allocationData: Object,
    allocationId: Number,
  },
  async created() {
    await this.getUserBackgroundCheck();
    await this.getVehiclesOptions();
    this.getStepParsedDate();

    this.allocationAnalysisStatus = this.allocationData.status;
    this.allocationStatus = this.allocationData.status;

    if (this.allocationData.investmentVehicleId) {
      this.selectedVehicle = this.allocationData.investmentVehicleId;
    }

    // so pode aprovar alocação se o background check estiver concluído
    if (
      this.backgroundCheckData.Status === BackgroundCheckStatusEnum.CONCLUDED
    ) {
      this.disableButtonSaveVehicle = false;
      this.disableInputSelectVehicle = false;
    }
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    getStepParsedDate() {
      const filteredStartStatus = this.allocationData.statusRecords.filter(
        (record) => record.status === allocationStatusEnums.PLACEMENT_REVIEW
      );

      const filteredEndStatus = this.allocationData.statusRecords.filter(
        (record) => record.status === allocationStatusEnums.FORMALIZATION
      );

      if (filteredStartStatus.length) {
        this.analysisStartDate = this.getAllocationDateParsed(
          filteredStartStatus[0].startedStatusAt,
          false
        );
      }

      if (filteredEndStatus.length) {
        this.analysisEndDate = this.getAllocationDateParsed(
          filteredEndStatus[0].startedStatusAt,
          false
        );
      }
    },
    vehicleIsRequired(status) {
      this.vehicleInputIsRequired =
        status === allocationStatusEnums.FORMALIZATION;
    },
    checkAllocationDisabled() {
      const literal = {
        [allocationStatusEnums.PLACEMENT_REVIEW]: false,
        [allocationStatusEnums.TRANSFER_FUNDS]: false,
        [allocationStatusEnums.FORMALIZATION]: false,
        [allocationStatusEnums.OPERATION_SETTING]: false,
        [allocationStatusEnums.SENT_CAPTABLE]: false,
        [allocationStatusEnums.CANCELLED]: false,
        [allocationStatusEnums.DENIED]: false,
        [allocationStatusEnums.PLACEMENT_REQUEST]: true,
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: true,
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: true,
      };
      return literal[this.allocationAnalysisStatus];
    },
    checkAnalystisAllocationStatus() {
      const literal = {
        [allocationStatusEnums.TRANSFER_FUNDS]: true,
        [allocationStatusEnums.FORMALIZATION]: true,
        [allocationStatusEnums.OPERATION_SETTING]: true,
        [allocationStatusEnums.SENT_CAPTABLE]: true,
        [allocationStatusEnums.CANCELLED]: false,
        [allocationStatusEnums.DENIED]: false,
        [allocationStatusEnums.PLACEMENT_REVIEW]: false,
        [allocationStatusEnums.PLACEMENT_REQUEST]: false,
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: false,
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: false,
      };

      return literal[this.allocationAnalysisStatus];
    },
    checkAnalysisStatusTransparent() {
      const literal = {
        [allocationStatusEnums.FORMALIZATION]: true,
        [allocationStatusEnums.TRANSFER_FUNDS]: true,
        [allocationStatusEnums.OPERATION_SETTING]: true,
        [allocationStatusEnums.SENT_CAPTABLE]: true,
        [allocationStatusEnums.CANCELLED]: true,
        [allocationStatusEnums.DENIED]: true,
        [allocationStatusEnums.PLACEMENT_REVIEW]: false,
        [allocationStatusEnums.PLACEMENT_REQUEST]: false,
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: false,
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: false,
      };
      return literal[this.allocationAnalysisStatus];
    },
    getAllocationDateParsed(date, time = true) {
      if (!date) {
        return "-";
      }

      return format(new Date(date), `dd/MM/yyyy ${time ? "HH:mm" : ""}`, {
        locale: ptBR,
      });
    },
    getBackgroundCheckStatus() {
      const status = {
        [BackgroundCheckStatusEnum.ANALYSIS]: "company_analysis",
        [BackgroundCheckStatusEnum.CONCLUDED]: "concluded",
        [BackgroundCheckStatusEnum.PROCESSING]: "processing",
      };

      return status[this.backgroundCheckData.Status];
    },
    getBackgroundCheckResult() {
      const status = {
        [BackgroundCheckResultEnum.NOT_REVIEWED]: "not_revised",
        [BackgroundCheckResultEnum.REVIEW]: "reviewed",
        [BackgroundCheckResultEnum.INVALID]: "invalid",
        [BackgroundCheckResultEnum.VALID]: "valid",
      };

      return status[this.backgroundCheckData.Result];
    },
    getAllocationStatusTextAndIcon() {
      const status = {
        [allocationStatusEnums.FORMALIZATION]: {
          text: "approved_allocation",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
        },
        [allocationStatusEnums.TRANSFER_FUNDS]: {
          text: "approved_allocation",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
        },
        [allocationStatusEnums.OPERATION_SETTING]: {
          text: "approved_allocation",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
        },
        [allocationStatusEnums.SENT_CAPTABLE]: {
          text: "approved_allocation",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
        },
        [allocationStatusEnums.DENIED]: {
          text: "denied_allocation",
          icon: "fa-circle-xmark",
          color: "#FF0000",
          iconColor: "#FF0000",
        },
        [allocationStatusEnums.CANCELLED]: {
          text: "denied_allocation",
          icon: "fa-circle-xmark",
          color: "#FF0000",
          iconColor: "#FF0000",
        },
        [allocationStatusEnums.PLACEMENT_REVIEW]: {
          text: "waiting_dxa_approved",
          icon: "fa-triangle-exclamation",
          color: "#FF9601",
          iconColor: "#FF9601",
        },
        // trocar para estilo disabled
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: {
          text: "cancelada",
          icon: "fa-hourglass-start",
          color: "#AFAFAF",
          iconColor: "#AFAFAF",
        },
        // trocar para estilo disabled
        [allocationStatusEnums.PLACEMENT_REQUEST]: {
          text: "cancelada",
          icon: "fa-hourglass-start",
          color: "#AFAFAF",
          iconColor: "#AFAFAF",
        },
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: {
          text: "cancelada",
          icon: "fa-hourglass-start",
          color: "#AFAFAF",
          iconColor: "#AFAFAF",
        },
      };

      return status[this.allocationAnalysisStatus];
    },
    async getVehiclesOptions() {
      // get dos veículos de acordo com a empresa da alocação
      this.allLoading = true;
      this.vehicleOptionsLoading = true;
      await this.apiService
        .getRequest(
          `investmentvehicle/company-vehicles/${this.allocationData.companyId}`
        )
        .then((response) => {
          this.vehicleOptions = response.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.allLoading = false;
      this.vehicleOptionsLoading = false;
    },
    async getUserBackgroundCheck() {
      this.allLoading = true;
      await this.apiService
        .getRequest(`backgroundcheck/status/${this.allocationData.investorId}`)
        .then((response) => {
          this.backgroundCheckData = JSON.parse(response.message);
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.allLoading = false;
    },
    checkForm: function () {
      if (this.loading) {
        return;
      }
      this.loading = true;

      // quando não for cancelar a alocação faz o check do form todo mas quando for para cancelar a alocação vai verificar só se os
      // input de status foi preenchido antes de chamar o submit
      if (!(this.allocationStatus === allocationStatusEnums.DENIED)) {
        let result = this.$refs.form.validate();

        if (result) {
          this.handleAnalysisSubmit();
        } else {
          this.loading = false;
        }
      } else {
        if (this.allocationStatus) {
          this.handleAnalysisSubmit();
        } else {
          this.loading = false;
        }
      }
    },
    async handleAnalysisSubmit() {
      this.submitLoading = true;

      let payload = {
        status: this.allocationStatus,
        id: this.allocationId,
      };

      if (this.allocationStatus !== allocationStatusEnums.DENIED) {
        payload.investmentVehicleId = this.selectedVehicle;
      }

      await this.apiService
        .putRequest("companyinterest/reply", payload)
        .then((response) => {
          this.$toast.success(this.$t("saved"));
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.submitLoading = false;
    },
    async handleClickUpdateAllocationStatus() {
      this.updateAllocationStatusLoading = true;
      await this.getUserBackgroundCheck();
      this.updateAllocationStatusLoading = false;
    },
    async handleClickRequestNewAllocationStatus() {
      this.requestNewAllocationStatusLoading = true;
      await this.apiService
        .postRequest(`backgroundcheck/${this.allocationData.investorId}`)
        .then((response) => {
          this.$toast.success(this.$t("saved"));
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        });
      this.requestNewAllocationStatusLoading = false;
    },
  },
};
</script>

<style scoped>
ul,
li,
p,
h2,
h1 {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

.allocation-analysis-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.allocation-analysis-header-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);

  display: flex;
  align-items: center;
  gap: 18px;
}

.allocation-analysis-header-title > svg {
  width: 28px;
  height: 28px;
}

#allocation-analysis-header {
  position: relative;
}

.allocation-analysis-header-status {
  position: absolute;
  bottom: 10px;
  left: 0;
}

.allocation-analysis-header-date-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.allocation-analysis-header-date-content > span {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
}

.allocation-analysis-status {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  margin-bottom: 10px;
  display: block;
  margin-left: 20px;
}

.allocation-analysis-header-date-content > span.date {
  font-weight: 700;
  margin-top: 8px;
}

.allocation-analysis-header {
  margin-bottom: 32px;
}

.allocation-analysis-header-date-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
}

.allocation-analysis-status {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  margin-bottom: 10px;
  display: block;
  margin-left: 20px;
}

.allocation-analysis-message-to-user {
  background: var(--white);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
}

.allocation-analysis-message-to-user h2,
.allocation-analysis-message-to-user p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
}

.allocation-analysis-message-to-user h2 {
  font-weight: bold;
  margin-bottom: 4px;
}

.allocation-analysis-user-background-check {
  border-top: 1px solid #535353;
  border-bottom: 1px solid #535353;
  padding: 40px 20px;
}

.allocation-analysis-section-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 30px;
}

.allocation-analysis-user-background-check-list,
.allocation-analysis-allocation-status-list {
  display: flex;
  align-items: center;
  gap: 40px;
}
.allocation-analysis-user-background-check-list > li span,
.allocation-analysis-allocation-status-list > li span {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
}

.allocation-analysis-user-background-check-list > li span.value,
.allocation-analysis-allocation-status-list > li span.value {
  font-weight: 700;
  margin-top: 10px;
}

.allocation-analysis-user-background-check-buttons-flex {
  display: flex;
  align-items: center;
  gap: 40px;
}

.allocation-analysis-user-background-check {
  display: flex;
  justify-content: space-between;
}

.allocation-analysis-user-background-check-buttons-flex {
  align-self: flex-end;
}

.allocation-analysis-radiou-label {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.allocation-analysis-content-section-3 {
  padding: 20px;
}

.allocation-analysis-select-investment-vehicle span.input-label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
  margin-bottom: 8px;
}

.allocation-analysis-select-investment-vehicle {
  margin-top: 30px;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
}

.allocation-analysis-select-investment-vehicle-button-save {
  min-width: 340px;
}

.allocation-analysis-select-investment-input-container {
  min-width: 420px;
}

.input-label-disabled {
  color: #afafaf !important;
}

.background-transparent {
  background-color: transparent !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.item-center {
  display: grid;
  place-items: center;
}
</style>
