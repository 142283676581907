<template>
  <v-expansion-panels>
    <div v-if="allLoading" class="item-center mb-4">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>
    <v-expansion-panel
      v-else
      :class="{
        'background-transparent': checkFormalizationStatusTransparent(),
      }"
    >
      <v-expansion-panel-header id="allocation-formalization-header">
        <div class="allocation-formalization-header">
          <h1
            class="allocation-formalization-header-title"
            :style="disabledStep && 'color: #AFAFAF'"
          >
            <font-awesome-icon
              :icon="`fa-solid ${getAllocationStatusTextAndIcon().icon}`"
              class="allocation-formalization-header-title-icon"
              :color="getAllocationStatusTextAndIcon().iconColor"
            />
            {{ $t("formalization") }}
          </h1>
          <div class="allocation-formalization-header-date-container">
            <div class="allocation-formalization-header-date-content">
              <span :style="disabledStep && 'color: #AFAFAF'">{{
                $t("step_start")
              }}</span>
              <span class="date" v-if="formalizationStartDate">{{
                formalizationStartDate
              }}</span>
              <span v-else>-</span>
            </div>
            <div class="allocation-formalization-header-date-content">
              <span :style="disabledStep && 'color: #AFAFAF'">{{
                $t("step_completion")
              }}</span>
              <span class="date" v-if="formalizationEndDate">{{
                formalizationEndDate
              }}</span>
              <span class="date" v-else>-</span>
            </div>
          </div>
        </div>
        <div class="allocation-formalization-header-status">
          <span
            class="allocation-formalization-status"
            :style="`color: ${getAllocationStatusTextAndIcon().color}`"
            v-if="!disabledStep"
          >
            {{ $t(getAllocationStatusTextAndIcon().text) + "." }}
          </span>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          class="allocation-formalization-content"
          :class="{ 'border-bottom': !disabledStep && vehicleData }"
        >
          <span
            v-if="disabledStep"
            class="allocation-formalization-status"
            :style="`color: ${getAllocationStatusTextAndIcon().color}`"
          >
            {{ $t("step_not_started") }}</span
          >
          <div class="allocation-formalization-message-to-user">
            <h2>{{ $t("show_user_message_title") }}</h2>
            <p>
              {{ $t("show_user_message_content") }}
            </p>
          </div>
        </div>
        <div v-if="!disabledStep && vehicleData">
          <h2 class="allocation-formalization-vehicle-documents-title">
            {{
              $t("vehicle_documents", {
                vehicle: allocationData.investmentVehicleName,
              })
            }}
          </h2>
          <div
            class="documents-to-download"
            v-if="
              vehicleData.closingType == vehicleClosingOptionsEnum.CLOSING_SA
            "
          >
            <div
              class="file-section border-bottom"
              @click="
                handleDownloadDocuments(
                  vehicleData.bylaws,
                  AllocationFormalizationFilledLoading.BYLAWS
                )
              "
            >
              <v-progress-circular
                v-if="
                  filledLoading.loading &&
                  filledLoading.filled ===
                    AllocationFormalizationFilledLoading.BYLAWS
                "
                indeterminate
                size="20"
                color="primary"
              ></v-progress-circular>
              <font-awesome-icon
                v-else
                class="icon-success icon-download cursor-pointer"
                icon="fa-solid fa-download"
                :class="{
                  disabled: !vehicleData.shareholderAgreement,
                }"
              />
              <span
                class="document-name cursor-pointer"
                :class="{
                  disabled: !vehicleData.bylaws,
                }"
                :style="`${
                  filledLoading.loading &&
                  filledLoading.filled ===
                    AllocationFormalizationFilledLoading.BYLAWS &&
                  'cursor: wait'
                }`"
                >{{ $t("bylaws") }}</span
              >
            </div>
            <div
              class="file-section border-bottom"
              @click="
                handleDownloadDocuments(
                  vehicleData.shareholderAgreement,
                  AllocationFormalizationFilledLoading.SHARE_HOLDER_AGREEMENT
                )
              "
            >
              <v-progress-circular
                v-if="
                  filledLoading.loading &&
                  filledLoading.filled ===
                    AllocationFormalizationFilledLoading.SHARE_HOLDER_AGREEMENT
                "
                indeterminate
                size="20"
                color="primary"
              ></v-progress-circular>
              <font-awesome-icon
                v-else
                class="icon-success icon-download cursor-pointer"
                icon="fa-solid fa-download"
                :class="{
                  disabled: !vehicleData.shareholderAgreement,
                }"
              />
              <span
                class="document-name cursor-pointer"
                :class="{
                  disabled: !vehicleData.shareholderAgreement,
                }"
                :style="
                  filledLoading.loading &&
                  filledLoading.filled ===
                    AllocationFormalizationFilledLoading.SHARE_HOLDER_AGREEMENT &&
                  'cursor: wait'
                "
              >
                {{ $t("shareholders_agreement") }}
              </span>
            </div>
          </div>

          <h2
            class="allocation-formalization-vehicle-documents-title allocation-formalization-vehicle-documents-title-flex"
          >
            {{ $t("formalization_documents") }}
            <span
              class="allocation-formalization-vehicle-documents-title-date"
              >{{ $t("sign_date") }}</span
            >
          </h2>

          <div
            class="documents-to-download"
            v-if="
              vehicleData.closingType == vehicleClosingOptionsEnum.CLOSING_SA
            "
          >
            <div class="file-section file-section-flex border-bottom">
              <div
                class="cursor-pointer"
                :class="{
                  disabled:
                    allocationFormalizationStatus ===
                      AllocationFormalizationEnum.WAITING_USER_FORMALIZATION ||
                    !formalizationDocuments.AdhesionTermSaved,
                }"
                @click="
                  downloadAllocationSignedDocument(
                    AllocationFormalizationFilledLoading.ADHESION_TERM
                  )
                "
              >
                <v-progress-circular
                  v-if="
                    filledLoading.loading &&
                    filledLoading.filled ===
                      AllocationFormalizationFilledLoading.ADHESION_TERM
                  "
                  indeterminate
                  size="20"
                  color="primary"
                ></v-progress-circular>
                <font-awesome-icon
                  v-else
                  class="icon-success icon-download cursor-pointer"
                  icon="fa-solid fa-download"
                />
                <span
                  :style="
                    filledLoading.loading &&
                    filledLoading.filled ===
                      AllocationFormalizationFilledLoading.ADHESION_TERM &&
                    'cursor: wait'
                  "
                  class="document-name"
                  >{{ $t("round_closing_adhesion_term") }}</span
                >
              </div>
              <span
                class="waiting-user-label"
                v-if="
                  allocationFormalizationStatus ===
                  AllocationFormalizationEnum.WAITING_USER_FORMALIZATION
                "
                >{{ $t("wainting_user") }}</span
              >
              <span v-else class="date-label">{{
                getAllocationDateParsed(
                  formalizationDocuments.AdhesionTermSignatureDate,
                  false
                )
              }}</span>
            </div>
            <div class="file-section file-section-flex">
              <div
                class="cursor-pointer"
                :class="{
                  disabled:
                    allocationFormalizationStatus ===
                      AllocationFormalizationEnum.WAITING_USER_FORMALIZATION ||
                    !formalizationDocuments.NewsletterSubscriptionSaved,
                }"
                @click="
                  downloadAllocationSignedDocument(
                    AllocationFormalizationFilledLoading.NEWS_LETTER_SUBSCRIPTION
                  )
                "
              >
                <v-progress-circular
                  v-if="
                    filledLoading.loading &&
                    filledLoading.filled ===
                      AllocationFormalizationFilledLoading.NEWS_LETTER_SUBSCRIPTION
                  "
                  indeterminate
                  size="20"
                  color="primary"
                ></v-progress-circular>
                <font-awesome-icon
                  v-else
                  class="icon-success icon-download cursor-pointer"
                  icon="fa-solid fa-download"
                />
                <span
                  :style="
                    filledLoading.loading &&
                    filledLoading.filled ===
                      AllocationFormalizationFilledLoading.NEWS_LETTER_SUBSCRIPTION &&
                    'cursor: wait'
                  "
                  class="document-name"
                  >{{ $t("newsletter_subscription") }}</span
                >
              </div>
              <span
                class="waiting-user-label"
                v-if="
                  allocationFormalizationStatus ===
                  AllocationFormalizationEnum.WAITING_USER_FORMALIZATION
                "
                >{{ $t("wainting_user") }}</span
              >
              <span v-else class="date-label">{{
                getAllocationDateParsed(
                  formalizationDocuments.NewsletterSignatureDate,
                  false
                )
              }}</span>
            </div>
          </div>

          <div
            class="documents-to-download"
            v-if="
              vehicleData.closingType == vehicleClosingOptionsEnum.CLOSING_LLC
            "
          >
            <div class="file-section file-section-flex border-bottom">
              <div
                class="cursor-pointer"
                :class="{
                  disabled:
                    allocationFormalizationStatus ===
                      AllocationFormalizationEnum.WAITING_USER_FORMALIZATION ||
                    !formalizationDocuments.SubscriptionAgreementSaved,
                }"
                @click="
                  downloadAllocationSignedDocument(
                    AllocationFormalizationFilledLoading.SUBSCRIPTION_AGREEMENT
                  )
                "
              >
                <v-progress-circular
                  v-if="
                    filledLoading.loading &&
                    filledLoading.filled ===
                      AllocationFormalizationFilledLoading.SUBSCRIPTION_AGREEMENT
                  "
                  indeterminate
                  size="20"
                  color="primary"
                ></v-progress-circular>
                <font-awesome-icon
                  v-else
                  class="icon-success icon-download cursor-pointer"
                  icon="fa-solid fa-download"
                />
                <span
                  :style="
                    filledLoading.loading &&
                    filledLoading.filled ===
                      AllocationFormalizationFilledLoading.SUBSCRIPTION_AGREEMENT &&
                    'cursor: wait'
                  "
                  class="document-name"
                  >{{ $t("round_closing_subscription_agreement") }}</span
                >
              </div>
              <span
                class="waiting-user-label"
                v-if="
                  allocationFormalizationStatus ===
                  AllocationFormalizationEnum.WAITING_USER_FORMALIZATION
                "
                >{{ $t("wainting_user") }}</span
              >
              <span v-else class="date-label">{{
                getAllocationDateParsed(
                  formalizationDocuments.SubscriptionAgreementSignatureDate,
                  false
                )
              }}</span>
            </div>
          </div>

          <div
            class="documents-to-download"
            v-if="
              vehicleData.closingType == vehicleClosingOptionsEnum.NO_CLOSING
            "
          >
            <span class="date">
              {{ $t("no_formalization_documents") }}
            </span>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {
  AllocationFormalizationEnum,
  AllocationFormalizationFilledLoading,
} from "../../enums/AllocationFormalizationEnum";
import ApiService from "@/services/ApiService";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { allocationStatusEnums } from "../../AllocationStatusEnums";
import { vehicleClosingOptionsEnum } from "@/shared/enums/VehicleEnum";
import { userFileEnums } from "@/shared/enums/UserFileEnums";
export default {
  name: "AllocationFormalization",
  components: {},
  data() {
    return {
      apiService: new ApiService(),
      allocationStatus: null,
      allocationFormalizationStatus: null,
      AllocationFormalizationEnum,
      userId: null,
      allocationVehicleId: 1,
      disabledStep: false,
      vehicleData: null,
      filledLoading: false,
      AllocationFormalizationFilledLoading,
      formalizationDocuments: null,
      formalizationStartDate: null,
      formalizationEndDate: null,
      allLoading: false,
      newsletterSubscriptionDownload: false,
      adhesionTermDownload: false,
      subscriptionAgreementDownload: false,
      userFileEnums,
      vehicleClosingOptionsEnum,
    };
  },
  props: {
    allocationId: Number,
    allocationData: Object,
    disabledComponent: Boolean,
  },
  async created() {
    await this.getAllocationsContracts();
    await this.getVehicleDocuments();
    this.getAllocationDates();
    this.allocationFormalizationStatus = this.allocationData.status;
    this.disabledStep = this.checkAllocationDisabled();
  },
  required() {
    return (value) => !!value || this.$t("required");
  },
  methods: {
    getAllocationDates() {
      const filteredStartStatus = this.allocationData.statusRecords.filter(
        (record) => record.status === allocationStatusEnums.FORMALIZATION
      );

      const filteredEndStatus = this.allocationData.statusRecords.filter(
        (record) => record.status === allocationStatusEnums.TRANSFER_FUNDS
      );

      if (filteredStartStatus.length) {
        this.formalizationStartDate = this.getAllocationDateParsed(
          filteredStartStatus[0].startedStatusAt,
          false
        );
      }

      if (filteredEndStatus.length) {
        this.formalizationEndDate = this.getAllocationDateParsed(
          filteredEndStatus[0].startedStatusAt,
          false
        );
      }
    },
    getAllocationDateParsed(date, time = true) {
      if (!date) {
        return "-";
      }

      return format(new Date(date), `dd/MM/yyyy ${time ? "HH:mm" : ""}`, {
        locale: ptBR,
      });
    },
    checkFormalizationStatusTransparent() {
      const literal = {
        [allocationStatusEnums.FORMALIZATION]: false,
        [allocationStatusEnums.TRANSFER_FUNDS]: true,
        [allocationStatusEnums.OPERATION_SETTING]: true,
        [allocationStatusEnums.SENT_CAPTABLE]: true,
        [allocationStatusEnums.CANCELLED]: true,
        [allocationStatusEnums.DENIED]: true,
        [allocationStatusEnums.PLACEMENT_REVIEW]: false,
        [allocationStatusEnums.PLACEMENT_REQUEST]: false,
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: false,
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: false,
      };
      return literal[this.allocationFormalizationStatus];
    },
    checkAllocationStatus() {
      const literal = {
        [allocationStatusEnums.TRANSFER_FUNDS]: true,
        [allocationStatusEnums.OPERATION_SETTING]: true,
        [allocationStatusEnums.SENT_CAPTABLE]: true,
        [allocationStatusEnums.FORMALIZATION]: false,
        [allocationStatusEnums.CANCELLED]: false,
        [allocationStatusEnums.DENIED]: false,
        [allocationStatusEnums.PLACEMENT_REVIEW]: false,
        [allocationStatusEnums.PLACEMENT_REQUEST]: false,
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: false,
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: false,
      };

      return literal[this.allocationFormalizationStatus];
    },
    checkAllocationDisabled() {
      const literal = {
        [allocationStatusEnums.PLACEMENT_REVIEW]: true,
        [allocationStatusEnums.PLACEMENT_REQUEST]: true,
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: true,
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: true,
        [allocationStatusEnums.TRANSFER_FUNDS]: false,
        [allocationStatusEnums.FORMALIZATION]: false,
        [allocationStatusEnums.OPERATION_SETTING]: false,
        [allocationStatusEnums.SENT_CAPTABLE]: false,
        [allocationStatusEnums.CANCELLED]: false,
        [allocationStatusEnums.DENIED]: false,
      };
      return literal[this.allocationFormalizationStatus];
    },
    handleDownloadDocuments(file, filledEnum) {
      if (file) {
        this.filledLoading = {
          loading: true,
          filled: filledEnum,
        };

        let extract = file.fileContent;
        let fileName = file.fileName;

        extract = "data:application/octet-stream;base64," + extract;
        const link = document.createElement("a");
        link.href = extract;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          this.filledLoading.loading = false;
        }, 1500);
      }
    },
    async getAllocationsContracts() {
      if (this.allocationData.investmentVehicleId) {
        this.allLoading = true;
        await this.apiService
          .postRequest(
            `companyinterest/allocation-contract-sign/${this.allocationId}`
          )
          .then((response) => {
            this.formalizationDocuments = JSON.parse(response.message);

            if (
              this.formalizationDocuments.NewsletterSubscriptionSaved ===
                false &&
              this.formalizationDocuments.NewsletterSubscriptionSignatureKey !==
                null &&
              this.formalizationDocuments.SignedNewsletterSubscription === true
            ) {
              this.newsletterSubscriptionDownload = true;
            }
            if (
              this.formalizationDocuments.AdhesionTermSaved === false &&
              this.formalizationDocuments.AdhesionTermSignatureKey !== null &&
              this.formalizationDocuments.SignedTerm === true
            ) {
              this.adhesionTermDownload = true;
            }
            if (
              this.formalizationDocuments.SubscriptionAgreementSaved ===
                false &&
              this.formalizationDocuments.SubscriptionAgreementSignatureKey !==
                null &&
              this.formalizationDocuments.SignedSubscriptionAgreement === true
            ) {
              this.subscriptionAgreementDownload = true;
            }
            this.updateFilesClicksign();
          })
          .catch((err) => {
            this.$toast.error(this.$t("error_occoured"));
          });
        this.allLoading = false;
      }
    },

    async updateFilesClicksign() {
      if (this.adhesionTermDownload) {
        this.allLoading = true;
        await this.apiService
          .getRequest(
            `companyinterest/save-signed-doc/${this.formalizationDocuments.AdhesionTermDocumentKey}`
          )
          .then((response) => {
            this.adhesionTermDownload = false;
            this.formalizationDocuments.AdhesionTermSaved = true;
          })
          .catch((err) => {
            this.$toast.error(this.$t("error_occoured"));
          });
        this.allLoading = false;
      }

      if (this.newsletterSubscriptionDownload) {
        this.allLoading = true;
        await this.apiService
          .getRequest(
            `companyinterest/save-signed-doc/${this.formalizationDocuments.NewsletterSubscriptionDocumentKey}`
          )
          .then((response) => {
            this.newsletterSubscriptionDownload = false;
            this.formalizationDocuments.NewsletterSubscriptionSaved = true;
          })
          .catch((err) => {
            this.$toast.error(this.$t("error_occoured"));
          });
        this.allLoading = false;
      }

      if (this.subscriptionAgreementDownload) {
        this.allLoading = true;
        await this.apiService
          .getRequest(
            `companyinterest/save-signed-doc/${this.formalizationDocuments.SubscriptionAgreementDocumentKey}`
          )
          .then((response) => {
            this.subscriptionAgreementDownload = false;
            this.formalizationDocuments.SubscriptionAgreementSaved = true;
          })
          .catch((err) => {
            this.$toast.error(this.$t("error_occoured"));
          });
        this.allLoading = false;
      }
    },
    async downloadAllocationSignedDocument(filledEnum) {
      let fileSaved = false;
      let userFileType = 0;
      if (
        filledEnum == this.AllocationFormalizationFilledLoading.ADHESION_TERM
      ) {
        fileSaved = this.formalizationDocuments.AdhesionTermSaved;
        userFileType = this.userFileEnums.ADHESION_TERM;
      } else if (
        filledEnum ==
        this.AllocationFormalizationFilledLoading.NEWS_LETTER_SUBSCRIPTION
      ) {
        fileSaved = this.formalizationDocuments.NewsletterSubscriptionSaved;
        userFileType = this.userFileEnums.NEWS_LETTER_SUBSCRIPTION;
      } else if (
        filledEnum ==
        this.AllocationFormalizationFilledLoading.SUBSCRIPTION_AGREEMENT
      ) {
        fileSaved = this.formalizationDocuments.SubscriptionAgreementSaved;
        userFileType = this.userFileEnums.SUBSCRIPTION_AGREEMENT;
      }

      if (fileSaved) {
        this.filledLoading = {
          loading: true,
          filled: filledEnum,
        };

        await this.apiService
          .getRequest(
            `companyinterest/file/${this.allocationId}/${userFileType}`
          )
          .then((resp) => {
            const fileName = resp.content.fileName;
            resp =
              "data:application/octet-stream;base64," +
              resp.content.fileContent;
            const link = document.createElement("a");
            link.href = resp;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            this.$toast.error(this.$t("error_occoured"));
          });

        this.filledLoading.loading = false;
      }
    },
    async getVehicleDocuments() {
      if (this.allocationData.investmentVehicleId) {
        this.allLoading = true;
        await this.apiService
          .getRequest(
            `investmentvehicle/${this.allocationData.investmentVehicleId}`
          )
          .then((response) => {
            this.vehicleData = response.content;
          })
          .catch((err) => {
            this.$toast.error(this.$t("error_occoured"));
          });
        this.allLoading = false;
      }
    },
    getAllocationStatusTextAndIcon() {
      const status = {
        [allocationStatusEnums.TRANSFER_FUNDS]: {
          text: "formalization_allocation",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
        },
        [allocationStatusEnums.OPERATION_SETTING]: {
          text: "formalization_allocation",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
        },
        [allocationStatusEnums.SENT_CAPTABLE]: {
          text: "formalization_allocation",
          icon: "fa-circle-check",
          color: "var(--dark)",
          iconColor: "var(--primary)",
        },
        [allocationStatusEnums.FORMALIZATION]: {
          text: "wainting_user_formalziation",
          icon: "fa-triangle-exclamation",
          color: "#7833FF",
          iconColor: "#FF9601",
        },
        [allocationStatusEnums.DENIED]: {
          text: "denied_allocation",
          icon: "fa-circle-xmark",
          color: "#FF0000",
          iconColor: "#FF0000",
        },
        [allocationStatusEnums.CANCELLED]: {
          text: "denied_allocation",
          icon: "fa-circle-xmark",
          color: "#FF0000",
          iconColor: "#FF0000",
        },
      };

      if (this.disabledStep) {
        return {
          text: "step_not_started",
          icon: "fa-hourglass-start",
          iconColor: "#AFAFAF",
          color: "#AFAFAF",
        };
      }

      return status[this.allocationFormalizationStatus];
    },
  },
};
</script>

<style scoped>
ul,
li,
p,
h2,
h1 {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

#allocation-formalization-header {
  position: relative;
}

.allocation-formalization-header-status {
  position: absolute;
  bottom: 10px;
  left: 0;
}

.allocation-formalization-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.allocation-formalization-header-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--dark);

  display: flex;
  align-items: center;
  gap: 18px;
}

.allocation-formalization-header-title > svg {
  width: 28px;
  height: 28px;
}

.allocation-formalization-header-date-content > span {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.allocation-formalization-header-date-content > span.date {
  font-weight: 700;
  margin-top: 8px;
}

.allocation-formalization-header {
  margin-bottom: 32px;
}

.allocation-formalization-header-date-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
}

.allocation-formalization-status {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  margin-bottom: 10px;
  display: block;
  margin-left: 20px;
}

.allocation-formalization-message-to-user {
  background: var(--white);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
}

.allocation-formalization-message-to-user h2,
.allocation-formalization-message-to-user p {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
}

.allocation-formalization-message-to-user h2 {
  font-weight: bold;
  margin-bottom: 4px;
}

.allocation-formalization-user-background-check {
  border-top: 1px solid #535353;
  border-bottom: 1px solid #535353;
  padding: 40px 20px;
}

.allocation-formalization-section-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 30px;
}

.allocation-formalization-user-background-check-list,
.allocation-formalization-allocation-status-list {
  display: flex;
  align-items: center;
  gap: 40px;
}
.allocation-formalization-user-background-check-list > li span,
.allocation-formalization-allocation-status-list > li span {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
}

.allocation-formalization-user-background-check-list > li span.value,
.allocation-formalization-allocation-status-list > li span.value {
  font-weight: 700;
  margin-top: 10px;
}

.allocation-formalization-user-background-check-buttons-flex {
  display: flex;
  align-items: center;
  gap: 40px;
}

.allocation-formalization-user-background-check {
  display: flex;
  justify-content: space-between;
}

.allocation-formalization-user-background-check-buttons-flex {
  align-self: flex-end;
}

.allocation-formalization-radiou-label {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.allocation-formalization-content-section-3 {
  padding: 20px;
}

.allocation-formalization-content {
  margin-bottom: 20px;
}

.allocation-formalization-select-investment-vehicle span.input-label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
  margin-bottom: 8px;
}

.allocation-formalization-select-investment-vehicle {
  margin-top: 30px;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
}

.allocation-formalization-select-investment-vehicle-button-save {
  min-width: 340px;
}

.allocation-formalization-select-investment-input-container {
  min-width: 420px;
}

.input-label-disabled {
  color: #afafaf !important;
}

.background-transparent {
  background-color: transparent !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.allocation-formalization-vehicle-documents-title {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 32px;
}

.border-bottom {
  border-bottom: 1px solid #535353;
}

.border-top {
  border-top: 1px solid #535353;
}

.file-section {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.icon-success {
  color: var(--primary);
  font-size: 22px;
}

.icon-download {
  font-size: 24px;
}

.document-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin-left: 20px;
}

.documents-to-download > div + div {
  margin-top: 24px;
}

.allocation-formalization-vehicle-documents-title-date {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
}

.allocation-formalization-vehicle-documents-title-flex {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.file-section-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.waiting-user-label {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.15px;
  color: #7833ff;
}

.disabled > svg,
.disabled > span,
.disabled {
  color: #afafaf !important;
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer;
}

.date-label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.item-center {
  display: grid;
  place-items: center;
}
</style>
